import { useGetEmployerQuery } from "../store/apiSlice";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import { setEmployer } from "../store/postsSlice";
import EmployerAddEdit from "./EmployerAddEdit";
import { useSelector, useDispatch } from "react-redux";
const EditEmployerProfile1 = ({ id = 7 }) => {
  const dispatch = useDispatch();
  const employer = useSelector((state) => state.posts.employer);
  console.log("EditEmployerProfile", id);
  const { data, isLoading, isSuccess } = useGetEmployerQuery(id);
  let content;
  if (isLoading) {
    content = "Loading";
  }
  if (data) {
    console.log("data=========", data);
    //dispatch(setEmployer(data))
    content = (
      <div className="overflow-y w-full">
        {employer?.company_name && (
          <div className="flex flex-col gap-2 p-4 bg-slate-100 rounded-t-xl mx-4">
            <div className="flex flex-wrap justify-start gap-1 items-center align-middle">
              <ul className="flex justify-start items-end gap-4 px-1">
                <div className="card w-[100px] h-[100px] bg-base-100 shadow-xl">
                  <div className="w-[100px] h-[100px] grid place-items-center">
                    <img
                      src={
                        employer?.logo
                          ? `https://academicjobs.s3.amazonaws.com/img/university-logo/${employer?.logo}`
                          : "/favicon.png"
                      }
                      alt={`${employer?.company_name}`}
                      className="object-contain rounded-md bg-white"
                    />
                  </div>
                </div>
              </ul>
              <div className="md:text-4xl text-xl font-black text-center font-serif overflow-hidden text-ellipsis whitespace-nowrap max-w-full">
                {employer?.company_name}
              </div>
            </div>

            <div className="flex mr-4 gap-2 ">
              <Link
                to={`/employers/${employer?.company_name
                  ?.replace(/\W+/g, "-")
                  .toLowerCase()}/${employer?.employer_id}/`}
                className="btn border-black text-white bg-amber-500 border-2 w-1/5"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Employer Profile
              </Link>
              <Link
                to={data?.website ? data?.website : "#"}
                className="btn border-black text-white bg-amber-500 border-2 w-1/5"
                target={data?.website ? "_blank" : "_self"}
                rel={data?.website ? "noopener noreferrer" : ""}
              >
                View Employer Website {data?.website ? "" : "(NA)"}
              </Link>
              <Link
                to={data?.employerPageURL ? data?.employerPageURL : "#"}
                className="btn border-black text-white bg-amber-500 border-2 w-1/5"
                target={data?.employerPageURL ? "_blank" : "_self"}
                rel={data?.employerPageURL ? "noopener noreferrer" : ""}
              >
                Employer Job Page URL {data?.employerPageURL ? "" : "(NA)"}
              </Link>
              <Link
                to={data?.contactPageURL ? data?.contactPageURL : "#"}
                className="btn border-black text-white bg-amber-500 border-2 w-1/5"
                target={data?.contactPageURL ? "_blank" : "_self"}
                rel={data?.contactPageURL ? "noopener noreferrer" : ""}
              >
                Staff Directory {data?.contactPageURL ? "" : "(NA)"}
              </Link>
              <Link to={`/post-a-job/`} className="btn border-black text-white bg-amber-500 border-2 w-1/5"

              >
                Post A Job
              </Link>
            </div>
          </div>
        )}
        <EmployerAddEdit employer={data} />
      </div>
    );
  }

  // { !isLoading ? <EmployerAddEdit employer={data} /> :  }
  return content;
};
export default EditEmployerProfile1;
