import React, { useState, useEffect, useRef, useMemo } from "react";
import { useForm, useWatch, useFieldArray } from "react-hook-form";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetCampusesMutation,
  useGetEmployerSuggestionsQuery,
  usePostAJobMutation,
  useGetEmployerQuery,
  useUpdateEmployerMutation,
  useUpdateJobMutation,
  useGetJobByIdQuery,
  useGetCategoriesQuery
} from "../store/apiSlice";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import InputBlock from "../components/ui/InputPostAJob";
import InputBlock2 from "./InputBlock2";
import JobKeywordSearchBlock from "./JobKeywordSearchBlock";
import SelectBlock2 from "./SelectBlock2";
import SelectBlock from "./SelectBlock";
import UniSearchBlock from "./UniSearchBlock";
import SelectBlockNew from "./SelectBlockNew";
import SelectBlock1 from "./SelectBlock1";
import job_category from "../data/JobCategories1.json";
import { countries } from "../utils/data";
import { currencies } from "../utils/data";
import { useNavigate, Link, NavLink } from "react-router-dom";
//import { setemployer.jobCredits } from "./postSlice"
import { regions } from "../utils/data";
import { setJob, setId, setEmployer } from "../store/postsSlice";
import { useSendEmail3Mutation, useSendJobPostNotificationMutation, useAddOtherContactsMutation } from "../store/apiSlice";
import { BsCheckLg } from "react-icons/bs";
import AppContextTitleAndQuote from "../components/profile/AppContextTitleAndQuote";
import ImageResize from 'quill-image-resize-module-react';
import RegionalPosting from "./RegionalPostingAdmin";
import { europeanCountries } from "../data/europeanCountries";
const validationSchema = yup
  .object({
    // username: yup.string().required('Missing username'),
    // email: yup.string().required('Missing email').email('Invalid email format'),
    //password: yup.string().required('Missing password'),
  })
  .required();
const academic_positions = {
  "Faculty Positions": [
    "Lecturer/Instructor",
    "Senior Lecturer",
    "Sessional Lecturer",
    "Tutor",
    "Teaching Assistant",
    "Adjunct Faculty",
    "Post-Doc",
    "Tenure",
    "Tenure-track",
  ],
  "Research Positions": [
    "Research",
    "Scientist/Associate",
    "Senior Research Assistant",
    "Research Assistant",
    "Research Fellow",
    "Post Doc Research Fellow",
    "Research Coordinator",
    "Research Manager",
    "Research Technician",
    "Faculty Researcher",
    "PhD Researcher",
  ],
  "Professorial Positions": [
    "Professor",
    "Assistant Professor",
    "Associate Professor",
    "Senior Professor",
    "Adjunct Professor",
    "Visiting Professor",
    "Research Professor",
    "Clinical Professor/Prof of Practice",
    "Emeritus Professor",
    "Visiting Scholar",
    "Visiting Fellow",
  ],
  "Executive Positions": [
    "Director/Manager",
    "Assistant Director",
    "Endowed",
    "Program Coordinator",
    "Clinical Chair",
  ],
  Others: [
    "Sessional Academic",
    "Clinical Educator",
    "Academic Advisor",
    "Curriculum Developer",
    "Educational Technologist",
    "Learning Specialist",
    "Academic Coach",
  ],
  Others1: [
    "Lab Manager",
    "Program Director",
    "Academic Coordinator",
    "Exam Supervisor",
    "Librarian",
    "Faculty Development Specialist",
    "Instructional Designer",
  ],
};

const subcategoryColumnMapping = {
  'Academic / Faculty': 'subcategory_academic_jobs',
  'Executive': 'subcategory_executive_jobs',
  'Human Resources': 'subcategory_hr_jobs',
  'Industry Jobs': 'subcategory_industry_jobs',
  'Staff / Administration': 'subcategory_administration_support',
  'Student / Phd Jobs': 'subcategory_studentPhd_jobs'
};

const JobAddEdit = ({ job }) => {
  const { data: jobCategoryData, isLoading, isError } = useGetCategoriesQuery();

  window.Quill = Quill
  Quill.register('modules/imageResize', ImageResize);
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };
  const formats = ['image'];
  const isAddMode = !job;
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const employer = useSelector((state) => state.posts.employer);
  const [startTime, setStartTime] = useState(Date.now());
  const [secondCategory, setSecondCategory] = useState(null);
  const initialEmail1Ref = useRef(job?.email1 || "");
  const initialEmail2Ref = useRef(job?.email2 || "");
  const initialFirstName1Ref = useRef(job?.firstName1 || "");
  const initialLastName1Ref = useRef(job?.lastName1 || "");
  const initialFirstName2Ref = useRef(job?.firstName2 || "");
  const initialLastName2Ref = useRef(job?.lastName2 || "");
  let defaultValues;
  let buttonText;
  const {
    control,
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(() => {
      return {
        // Your other default form values here...
        contacts: [{ name: "", email: "" }], // Initialize contacts with one row
        ...defaultValues, // Ensure you merge any other default values
      };
    }, [defaultValues]), // Add defaultValues to the dependencies if necessary
  });

  // const master_category_job_type = useWatch({
  //   name: "master_category_job_type",
  //   defaultValue: "default", // default value before the render
  // })
  let master_category_job_type = watch("master_category_job_type");
  //alert(master_category_job_type)
  const [AddOtherContacts1] = useAddOtherContactsMutation();
  const [
    sendEmail,
    {
      isSuccess: isSuccessSendEmail,
      isError: isErrorSendEmail,
      error: errorSendEmail,
    },
  ] = useSendEmail3Mutation();
  const [
    sendJobPostNotification,
    {
      isSuccess: isSuccessNotification,
      isError: isErrorNotification,
    },
  ] = useSendJobPostNotificationMutation();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (!job) {
      buttonText = "Post Job";
      reset({
        salary_period: "yearly",
        currency: "USD",
        activation_date: new Date().toISOString().substring(0, 10),
        expiration_date: new Date(
          new Date().getTime() + 30 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .substring(0, 10),
        remote: "Onsite",
        position_type: "",
      });
      if (employer?.clientType == "unlimited") {
        setValue("featured", true);
      }
      //alert()
      master_category_job_type = "Academic / Faculty";
      setValue("master_category_job_type", "Academic / Faculty");
    } else {
      buttonText = "Update Job";
      reset(job);
      setValue("thirdcategory", job.thirdcategory); // Explicitly set the third category
      if (job?.subcategory_academic_jobs) {
        setSecondCategory(job.subcategory_academic_jobs);
      } else if (job?.subcategory_executive_jobs) {
        setSecondCategory(job.subcategory_executive_jobs);
      }
      master_category_job_type = job.master_category_job_type;
      setValue(
        "activation_date",
        new Date(job.activation_date).toISOString().split("T")[0]
      );
      setValue(
        "expiration_date",
        new Date(job.expiration_date).toISOString().split("T")[0]
      );
      dispatch(
        setEmployer({
          company_name: job?.employer?.company_name,
          employer_id: job.employer_id,
          logo: job.employer?.logo,
          employerPageURL: job.employer?.employerPageURL,
          clientType: job.clientType,
        })
      );
    }
    // setValue('postedBy', userInfo.id)
    // if (employer) {
    //   setLogo(employer?.logo)
    //   reset(employer);
    // } else {
    //   reset({ featured: true, });
    // }
  }, [job]);
  useEffect(() => {
    register("description", { required: false });
    register("company_name1", { required: false });
    //register("country", { required: false });
  }, [register]);
  useEffect(() => {
    if (!job) {
      setValue("company_name1", employer?.company_name);
      setValue("employer_id", employer?.employer_id);
      setValue("headlineOnly", employer?.clientType != "unlimited");
    }
  }, [employer]);
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyCKEfoOIPz8l_6A8BByD3b3-ncwza8TNiA", //process.env.REACT_APP_GOOGLE,
    options: {
      componentRestrictions: { country: "Australia" },
    },
    onPlaceSelected: (place) => {
      //formik.setFieldValue("country", place.formatted_address);
    },
  });
  const jobStatusDefault =
    userInfo.employer_id > 0 && userInfo.jobCredits === 0
      ? "Save and Pay"
      : "Save Post";
  const [jobStatus, setJobStatus] = useState(jobStatusDefault);
  const editorContent = watch("description");
  const editorContent1 = watch("employer_name");
  const [query, setQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(true);
  const inputRef = useRef();
  const submitRef = useRef();
  const countryRef = useRef("");
  const { data: suggestions = [] } = useGetEmployerSuggestionsQuery(
    { query, country: countryRef.current || "" },
    {}
  );
  const [
    updateJob,
    {
      isLoading: isLoadingUpdateJob,
      isSuccess: isSuccessUpdateJob,
      isError: isErrorUpdateJob,
      error: errorUpdateJob,
    },
  ] = useUpdateJobMutation();
  const [
    postAJob,
    {
      data: dataPostAJob,
      isLoading: isLoadingPostAJob,
      isSuccess: isSuccessPostAJob,
      isError: isErrorPostAJob,
      error: errorPostAJob,
    },
  ] = usePostAJobMutation();
  const [newList, setNewList] = useState([]);
  const [newListName, setNewListName] = useState(null);
  const [newList1, setNewList1] = useState([]);
  const [newList1Name, setNewList1Name] = useState(null);
  const userid = useSelector((state) => state.auth.userid);
  const country = useSelector((state) => state.auth.country);
  const company_name = useSelector((state) => state.auth.company_name);
  const navigate = useNavigate();
  const job_type = ["Full time", "Part time", "Contractor", "Sessional"];
  //alert(process.env.REACT_APP_ENV)
  //alert(process.env.REACT_APP_GOOGLE)
  // const master_category_job_type = [
  //   "Academic / Faculty",
  //   "Executive",
  //   "Human Resources",
  //   "Industry Jobs",
  //   "Support /Administration",
  //   "Other",
  // ];
  const onEditorStateChange = (editorState) => {
    setValue("description", editorState);
  };
  const onEditorStateChange1 = (
    company_name,
    employer_id,
    Region,
    country,
    logo,
    employerPageURL,
    clientType
  ) => {
    setValue("company_name1", company_name);
    setValue("employer_id", employer_id || 0);
    setValue("Region", Region);
    setValue("country", country);
    setValue("logo", logo);
    //alert(id)
    dispatch(
      setEmployer({
        company_name,
        employer_id,
        logo,
        employerPageURL,
        clientType,
      })
    );
    if (employer_id) getCampuses({ id: employer_id });
  };
  function findValueForKey(array, keyToFind) {
    for (const obj of array) {
      const keys = Object.keys(obj);
      if (keys.includes(keyToFind)) {
        return obj[keyToFind];
      }
    }
    return null;
  }
  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts",
  });

  const sendEmailToContacts = async (jobId, jobTitle, employer_id) => {
    const contacts = getValues("contacts");
    for (const contact of contacts) {
      if (contact.email) {
        // Placeholder mutation for sending emails
        console.log(`Sending email to ${contact.name} at ${contact.email} for job ${jobTitle} (ID: ${jobId})`);
        await sendJobPostNotification({ email: contact.email, firstName: contact.firstName, jobId: jobId, title: jobTitle });
        AddOtherContacts1({
          email: contact.email,
          firstName: contact.firstName,
          lastName: contact.lastName,
          employer_id: employer_id,
          contactType: "OTHER",
          hrYN: contact.hr,
          departmentLeadYN: contact.department,
          recruiterYN: contact.recruiter
        });
      }
    }
  };

  const [getCampuses, { data: dataCampuses }] = useGetCampusesMutation();

  console.log(jobCategoryData);

  let content, content1, content2;
  const recordRef = useRef({ employer_id: userid });
  const handleChange1 = (newValue) => {
    setNewList(findValueForKey(job_category, newValue));
    setNewListName(newValue);
    setNewList1([]);
    setNewList1Name(null);
  };
  const handleChange2 = (newValue, flag) => {
    recordRef.current = { ...recordRef.current, [flag]: newValue };
    setNewList1(findValueForKey(job_category, newValue));
    setNewList1Name(newValue);
  };
  const handleChange3 = (newValue, flag) => {
    recordRef.current = { ...recordRef.current, [flag]: newValue };
  };
  const onSubmit = async (data) => {
    if (data.employer_id === 0) {
      alert('Please select an institution name from the dropdown. If the employer does not exist, create the employer first before posting a job.');
      return;
    }
    if (!data.location) {
      alert('Please add a location in the google autocomplete location field before saving.');
    } else {
      const result = isAddMode ? await createJob(data) : await updateJob1({ id: job?.id, ...data });

      // Clear the contacts field after job submission
      reset({
        ...data, // keep other form values intact
        contacts: [{ firstName: "", lastName: "", email: "", hr: false, department: false, recruiter: false }] // Reset contacts field to an empty contact
      });

      return result;
    }
  };

  async function createJob(data) {
    setJobStatus("Job Posting ......!");
    const {
      data: { id },
    } = await postAJob({
      ...data,
      jobPostDuration: Math.ceil((Date.now() - startTime) / 1000),
      postedBy: userInfo.id,
    });
    setJobStatus("Job POSTED!");
    if (id > 0) {
      setJobStatus("Job POSTED! Now Sending Email ....");
      if (getValues("email1")) {
        const { email1, firstName1, lastName1, title, employer_id, phone1, hrYN1, departmentYN1, recruiterYN1, jobTitle1 } = data;
        await sendEmail({
          email: email1,
          firstName: firstName1,
          jobId: id,
          jobTitle: title,
        });
        AddOtherContacts1({
          email: email1,
          phone: phone1,
          firstName: firstName1,
          lastName: lastName1,
          employer_id: employer_id,
          title: jobTitle1,
          contactType: "OTHER",
          hrYN: hrYN1,
          departmentYN: departmentYN1,
          recruiterYN: recruiterYN1
        });
      }

      if (getValues("email2")) {
        const { email2, firstName2, lastName2, title, employer_id, phone2, hrYN2, departmentYN2, recruiterYN2, jobTitle2 } = data;
        await sendEmail({
          email: email2,
          firstName: firstName2,
          jobId: id,
          jobTitle: title,
        });
        AddOtherContacts1({
          email: email2,
          phone: phone2,
          firstName: firstName2,
          lastName: lastName2,
          employer_id: employer_id,
          title: jobTitle2,
          contactType: "OTHER",
          hrYN: hrYN2,
          departmentYN: departmentYN2,
          recruiterYN: recruiterYN2
        });

      }
      if (getValues("contacts")) {
        await sendEmailToContacts(id, data.title, data.employer_id);
      }
      setJobStatus("Job POSTED! Email SENT!");
      navigate(`/jobs/edit/${id}`);
    }
  }
  async function updateJob1(data) {
    setJobStatus("Job Updating ......!");

    // Compare the current values of email1, email2, firstName1, lastName1, firstName2, and lastName2 with their initial values
    const isEmail1Updated = data.email1 && data.email1 !== initialEmail1Ref.current;
    const isFirstName1Updated = data.firstName1 && data.firstName1 !== initialFirstName1Ref.current;
    const isLastName1Updated = data.lastName1 && data.lastName1 !== initialLastName1Ref.current;

    const isEmail2Updated = data.email2 && data.email2 !== initialEmail2Ref.current;
    const isFirstName2Updated = data.firstName2 && data.firstName2 !== initialFirstName2Ref.current;
    const isLastName2Updated = data.lastName2 && data.lastName2 !== initialLastName2Ref.current;

    const response = await updateJob(data);

    if (response) {
      setJobStatus("Job UPDATED!");

      // If email1, firstName1, or lastName1 is updated, send the email to the first contact
      if (isEmail1Updated || isFirstName1Updated || isLastName1Updated) {
        const { email1, firstName1, title } = data;
        await sendEmail({ email: email1, firstName: firstName1, jobId: data.id, jobTitle: title });
        AddOtherContacts1({
          email: data.email1,
          phone: data.phone1,
          firstName: data.firstName1,
          lastName: data.lastName1,
          employer_id: data.employer_id,
          title: data.jobTitle1,
          contactType: "OTHER",
          hrYN: data.hrYN1,
          departmentYN: data.departmentYN1,
          recruiterYN: data.recruiterYN1
        });
      }

      // If email2, firstName2, or lastName2 is updated, send the email to the second contact
      if (isEmail2Updated || isFirstName2Updated || isLastName2Updated) {
        const { email2, firstName2, title } = data;
        await sendEmail({ email: email2, firstName: firstName2, jobId: data.id, jobTitle: title });
        AddOtherContacts1({
          email: data.email2,
          phone: data.phone2,
          firstName: data.firstName2,
          lastName: data.lastName2,
          employer_id: data.employer_id,
          title: data.jobTitle2,
          contactType: "OTHER",
          hrYN: data.hrYN2,
          departmentYN: data.departmentYN2,
          recruiterYN: data.recruiterYN2
        });
      }

      if (getValues("contacts")) {
        await sendEmailToContacts(data.id, data.title, data.employer_id);
      }

      navigate(`/jobs/edit/${data.id}`);
    }
  }
  if (isSuccessPostAJob || job) {

    const id = job?.id || dataPostAJob?.id;
    if (id) {
      content2 = (
        <div className="flex justify-start gap-2">
          <Link
            to={`https://www.academicjobs.com/jobs/${"company_name"
              .replace(/\W+/g, "-")
              .toLowerCase()}/${id}?active=true`}
            className="btn bg-yellow-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            view Posted Job
          </Link>
          <Link
            to={`https://www.academicjobs.com/jobs/myjob/${id}?mode=share`}
            className="btn bg-amber-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            Academic Connect
          </Link>

          {/* <button
            className="btn btn-primary"
            onClick={() => {
              if (window.confirm(`====ALERT=====
              Are you sure to permanetly delete this job:
              title: ${job?.title} 
              employer:  ${job?.company_name}   
              ?`)) {
              }
            }}
          > DELETE JOB</button> */}
          <button
            className="btn btn-primary"
            onClick={() => {
              if (
                window.confirm(`====ALERT=====
              Are you sure to EXPIRE this job:
              title: ${job?.title} 
              employer:  ${job?.company_name}   
              ?`)
              ) {
                setValue(
                  "expiration_date",
                  new Date("1900-01-01").toISOString().split("T")[0]
                );
                handleSubmit(onSubmit);
              }
            }}
          >
            {" "}
            EXPIRE THIS JOB
          </button>
        </div>
      );
    }
  }
  const jobCreditCount = employer?.jobCredits || 0; //FIXME: get from employer profile
  content = (
    <>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex justify-center gap-8"
      >
        <div className="w-1/2 flex flex-col gap-2">
          <div className="flex justify-start gap-1">
            <ul className=" flex justify-start items-end gap-4   px-1  ">
              <div className="card bg-base-100 border border-1 p-2 border-slate-300 w-[100px] h-[100px] grid place-items-center">
                <img
                  src={
                    employer?.logo
                      ? `https://academicjobs.s3.amazonaws.com/img/university-logo/${employer?.logo}`
                      : "/favicon.png"
                  }
                  alt={`${company_name}`}
                  className="object-contain rounded-md bg-white"
                />
              </div>
            </ul>

            <div className=" w-[100%]">

              {/* {employer?.employerPageURL &&
                                <Link className="  text-blue-500 " to={employer?.employerPageURL}>Institution Recruitment Page </Link>
                            } */}
              <div className=" w-[100%] mx-auto flex flex-col gap-1 ">
                {employer?.company_name && (
                  <label className="label-text font-black pb-2 text-blue-800 text-xl">
                    {employer?.company_name}
                  </label>
                )}
                <label className="label-text font-bold pb-2  text-lg">
                  Institution Name
                </label>
                <UniSearchBlock
                  register={register}
                  field="employer_name"
                  customKey="employer_name"
                  label="Start typing institution name and select from dropdown"
                  value1={employer?.company_name}
                  forceClass="mb-6"
                  onChange={onEditorStateChange1}
                />

              </div>

            </div>

          </div>
          {(job?.sourceEmployerName) && <><p>Relevant Employer: <span className="font-bold">{job?.sourceEmployerName}</span><p>Use most specific word for best search results.</p></p><p>If employer does not exist <a target="_blank" style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} href="/create-employer/">create new employer</a></p></>}

          <label className="label-text  text-lg mt-4 font-bold">
            Job Title
          </label>
          <InputBlock2
            type="text"
            field="title"
            label=""
            register={register}
            errors={errors}
            forceClass=""
          />

          <div className="border border-blue-500 rounded-xl p-4 my-4">
            {job &&

              <div className="flex justify-end">
                <button
                  className="btn text-blue-500 font-bold"
                  onClick={() => {
                    const content = `
                      Please generate SEO-optimized content for an academic job posting following these specifications:
                      1. H1 Title Creation
                      • If title not provided, extract key role/position from job description
                      • Use abbreviated university name if available (e.g., QUT instead of Queensland University of Technology)
                      • Format: [University] + [Position Type] + [Location/Mode] + ("Higher Ed Jobs" OR "University Jobs")
                      • Include location when specified (city/country)
                      • Add "Remote" if position allows remote work
                      • Keep length between 50-60 characters for optimal SEO
                      • Prioritize high-value keywords: Uni Jobs (Australia), Higher Ed Jobs (Global), University Jobs (Global)
                      2. Marketing Blurb Requirements
                      • Two concise paragraphs (approximately 100-150 words total)
                      • First paragraph: Focus on the position and institution
                        o Highlight key responsibilities
                        o Emphasize prestigious aspects of the role/institution
                        o Include relevant keywords naturally
                      • Second paragraph: Focus on candidate requirements and benefits
                        o Mention key qualifications
                        o Include location requirements if any
                        o End with a varied call-to-action (rotate among):
                           "View complete position details below."
                           "Discover more about this exciting opportunity below."
                           "See full role description and application details below."
                           "Learn more about this prestigious position below."
                      3. Required Keywords (Use As Appropriate)
                        • Australian positions: "uni jobs"
                        • Global positions: "higher ed jobs", "university jobs"
                        • Specific roles: "research jobs", "remote jobs", "adjunct professor jobs" (US only)
                      4. Optimization Rules
                        • Ensure natural keyword placement
                        • Avoid keyword stuffing
                        • Maintain conversational tone
                        • Focus on value proposition
                        • Include location-specific terms when relevant

                      Job Title: ${job?.title}
                      Job Description: ${job?.description}
                    `;
                    navigator.clipboard.writeText(content).then(() => {
                      alert("Content copied to clipboard!");
                    });
                  }}
                >
                  Copy ChatGPT Prompt
                </button>
              </div>
            }
            <label className="label-text  text-sm font-bold">
              Job Marketing Title
            </label>
            <InputBlock2
              type="text"
              field="marketingTitle"
              label=""
              register={register}
              errors={errors}
              forceClass=""
            />

            <label className="label-text  text-sm font-bold">
              Job Marketing Blurb (with keywords)
            </label>
            <textarea
              name="marketingBlurb"
              className="w-full h-[100px] border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              {...register("marketingBlurb")}
              aria-invalid={errors?.marketingBlurb ? "true" : "false"}
            ></textarea>
            {errors?.marketingBlurb && (
              <span className="text-red-500 text-sm" role="alert">
                {errors.marketingBlurb.message}
              </span>
            )}

          </div>

          <div className="flex gap-2 ">
            <div className=" w-[100%] mx-auto flex justify-start items-start  gap-1 ">
              <input type="checkbox" {...register("featured")} />
              <label className="label-text text-sm">Featured</label>
            </div>
            <div className=" w-[100%] mx-auto flex justify-start items-start  gap-1 ">
              <input type="checkbox" {...register("internalonly")} />
              <label className="label-text text-sm">Hide Job</label>
            </div>
            <div className=" w-[100%] mx-auto flex justify-start items-start  gap-1 ">
              <input type="checkbox" {...register("headlineOnly")} />
              <label className="label-text text-sm">Headline Only</label>
            </div>
          </div>
          <div className=" w-[100%] mx-auto flex justify-start items-start  gap-1 mt-4 ">
            <input className="mt-1" type="checkbox" {...register("indigenousJobYN")} />
            <label className="label-text text-sm">Is this an Indigenous job?</label>
            <figure>
              <img
                src={'/australian-aboriginal-flag.png'}
                alt="Indigenous Australian Aboriginal Flag"
                width={25}
                height={25}
                className="ml-2 max-w-7"
              />
            </figure>
            <figure>
              <img
                src={'/australia-torres-streight-islanders.svg'}
                alt="Indigenous Australian Aboriginal Flag"
                width={25}
                height={25}
                className="ml-2 max-w-7"
              />
            </figure>
            <figure>
              <img
                src={'/Flag-of-Maori.png'}
                alt="Maori Flag"
                width={25}
                height={25}
                className="ml-2 max-w-7"
              />
            </figure>

          </div>
          <label className="label-text  text-lg mt-4 font-bold">
            Academic Leads
          </label>
          <div className="flex items-center justify-start mt-4">
            <div className=" w-[100%] mx-auto flex justify-start items-start  gap-1 ">
              <input type="checkbox" {...register("academicLeads")} />
              <label className="label-text text-sm">Paid Job - academic leads required</label>
            </div>
          </div>
          <div className="mb-6 mt-4">
            <label className="label-text  pb-2  text-lg mt-4 font-bold">
              Job Description
            </label>
            <ReactQuill
              modules={modules} theme="snow"
              className="job_post_editor w-full mb-6"
              value={editorContent}
              onChange={onEditorStateChange}
            />
          </div>
        </div>

        <div className="w-1/2 flex flex-col gap-2">
          <div className="sticky top-0 z-10 flex justify-end">
            <button type="submit" className="btn btn-success mt-4" ref={submitRef}>
              {jobStatus}
            </button>
          </div>
          <JobKeywordSearchBlock
            field="Enter a keyword"
            customKey="Enter a keyword"
            label="Enter a keyword"
            forceClass="mb-6"
            onSelect={(specialty_name, subcategory_name, subcategory_column, master_category_name) => {
              setValue("master_category_job_type", master_category_name);
              setValue(subcategory_column, subcategory_name)
              setSecondCategory(subcategory_name)
              setValue("thirdcategory", specialty_name)
            }}
            //onInputChange={onInputChange}
            className="w-full"
          />
          {jobCategoryData &&
            <div>
              {/* Example for Master Category */}
              <div className="col-start-2 w-full flex flex-col items-start">
                <label className="label-text font-bold pb-2 text-lg">General Job Type</label>
                <ul className="w-full list-none flex flex-wrap gap-2 p-0 mb-4">
                  {Object.keys(jobCategoryData).map((masterCategory) => (
                    <div className="w-1/4 flex" key={masterCategory}>
                      <div className="text-left">
                        <input
                          type="radio"
                          className="radio radio-warning radio-xs mr-1"
                          value={masterCategory}
                          {...register('master_category_job_type')}
                        />
                      </div>
                      <label className="text-xs text-left text-gray-600">{masterCategory}</label>
                    </div>
                  ))}
                </ul>
              </div>

              {/* Example for Subcategory */}
              {master_category_job_type && (
                <div className="flex flex-col items-start">
                  <label className="label-text pb-2 text-lg mt-4">{`${master_category_job_type} Jobs`}</label>
                  <ul className="w-full list-none flex flex-wrap gap-2 p-0 mb-4">
                    {Object.entries(jobCategoryData[master_category_job_type].subCategories || {}).map(
                      ([subCategoryName, subCategoryData]) => (
                        <div className="w-1/4 flex" key={subCategoryData.id}>
                          <div className="text-left">
                            <input
                              type="radio"
                              className="radio radio-warning radio-xs mr-1"
                              value={subCategoryName}
                              {...register(subcategoryColumnMapping[master_category_job_type])}
                              onChange={(e) => setSecondCategory(subCategoryName)}
                            />
                          </div>
                          <label className="text-xs text-left text-gray-600">{subCategoryName}</label>
                        </div>
                      )
                    )}
                  </ul>
                </div>
              )}

              {/* Example for Specialties */}
              {master_category_job_type &&
                jobCategoryData[master_category_job_type] &&
                Object.entries(jobCategoryData[master_category_job_type].subCategories || {}).map(
                  ([subCategoryName, subCategoryData]) =>
                    subCategoryData.specialties && subCategoryData.specialties.length > 0 && (
                      <div
                        key={subCategoryData.id}
                        className={`flex flex-col items-start ${secondCategory === subCategoryName ? 'block' : 'hidden'}`}
                      >
                        <label className="label-text pb-2 text-lg mt-4">{`${subCategoryName} Specialties`}</label>
                        <ul className="w-full list-none flex flex-wrap gap-2 p-0 mb-4">
                          {subCategoryData.specialties.map((specialty) => {
                            // Create a unique identifier for the specialty
                            const specialtyKey = `${subCategoryName}-${specialty.name}`;
                            return (
                              <div className="w-1/4 flex" key={specialty.id}>
                                <div className="text-left">
                                  <input
                                    type="radio"
                                    className="radio radio-warning radio-xs mr-1"
                                    value={specialty.name}
                                    {...register('thirdcategory')}
                                    id={specialtyKey} // Add unique ID for accessibility
                                    checked={watch('thirdcategory') === specialty.name && secondCategory === subCategoryName}
                                    onChange={() => {
                                      // Ensure the correct subcategory is tracked
                                      setValue('secondCategory', subCategoryName);
                                      setValue('thirdcategory', specialty.name);
                                    }}
                                  />
                                </div>
                                <label className="text-xs text-left text-gray-600" htmlFor={specialtyKey}>
                                  {specialty.name}
                                </label>
                              </div>
                            );
                          })}
                        </ul>
                      </div>
                    )
                )}

            </div>}



          <div
            className={`flex flex-col gap-2 mb-6 mt-6  ${master_category_job_type === "Academic / Faculty"
              ? "block"
              : "hidden"
              }`}
          >
            {/* <SelectBlock2 list={position_type} field="position_type" label="Academic Position Type" register={register} errors={errors} forceClass=" font-bold" /> */}
            <label className="label-text font-bold text-lg">
              Academic Position Types:
            </label>
            <div className="flex flex-wrap gap-4">
              {Object.entries(academic_positions).map(
                ([category, positions]) => (
                  <div
                    key={category}
                    className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                  >
                    {category === "Others" && (
                      <h2 className="text-base font-black underline mb-2">
                        {category}
                      </h2>
                    )}
                    <ul className="list-none p-0 mb-4">
                      {positions.map((position, index) => (
                        <div className="flex gap-1">
                          <div className=" text-left">
                            <input
                              type="checkbox"
                              value={position}
                              defaultChecked={job?.positiontypes.some(
                                (p) => p.positionType === position
                              )}
                              {...register("positiontypes")}
                            />
                          </div>
                          <label
                            className={`text-sm text-left text-gray-600 ${index === 0 &&
                              category !== "Others" &&
                              category !== "Others1"
                              ? "font-black underline"
                              : ""
                              }`}
                          >
                            {position}
                          </label>
                        </div>
                      ))}
                    </ul>
                  </div>
                )
              )}
            </div>
            {/* <div className="flex flex-wrap gap-2 mb-1 ">
                            {academic_positions.map((position) => (
                                <div className="flex gap-1">
                                    <label className="text-sm text-right  font-bold">{position}
                                    </label>
                                    <div className=" text-left">
                                        <input type="checkbox" value={position}
                                            defaultChecked={job?.positiontypes.some((p) => p.positionType === position)}
                                            {...register('positiontypes')}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div> */}
          </div>
          <SelectBlock2
            list={job_type}
            field="job_type"
            label="Employment Type"
            register={register}
            errors={errors}
            forceClass=" font-bold"
          />
          <div className="label">
            <span className="label-text  pb-2  text-lg font-bold mb-[-10px]">
              Job Location
            </span>
            <span className="text-sm ">
              Start typing job location & allow Google to auto-complete
            </span>
          </div>
          <div>
            <Autocomplete
              className="border-2 rounded mt-2 text-pink-400"
              style={{ width: "100%" }}
              apiKey="AIzaSyCKEfoOIPz8l_6A8BByD3b3-ncwza8TNiA"
              {...register("location1")}
              onPlaceSelected={(place) => {
                const addressComponents = place.address_components || [];
                let city = "";
                let state = "";
                let country1 = "";
                let postalCode = "";

                addressComponents.forEach((component) => {
                  const types = component.types || [];
                  if (types.includes("locality")) {
                    city = component.long_name;
                  } else if (types.includes("administrative_area_level_1")) {
                    state = component.long_name;
                  } else if (types.includes("country")) {
                    country1 = component.long_name;
                  } else if (types.includes("postal_code")) {
                    postalCode = component.long_name;
                  }
                });

                // Extract latitude and longitude
                const latitude = place.geometry?.location?.lat();
                const longitude = place.geometry?.location?.lng();

                setValue("location", place.formatted_address);
                setValue("city", city);
                setValue("state", state);
                setValue("country", country1);

                // Check if the country is in Europe, set region accordingly
                const region = europeanCountries.includes(country1) ? "Europe" : country1;
                setValue("region", region);

                setValue("postalCode", postalCode);

                // Set latitude and longitude if available
                if (latitude !== undefined && longitude !== undefined) {
                  setValue("latitude", latitude);
                  setValue("longitude", longitude);
                }
              }}
              options={{
                types: ["geocode", "establishment"],
              }}
              onChange={(e) => setValue("location", e.target.value)}
              onError={(status, clearSuggestions) => {
                console.error("Google Maps API returned error with status: ", status);
                clearSuggestions();
              }}
              debounce={300}
            />
            <div>{job?.location}</div>
            {/* <InputBlock2 className="font-bold border-pink-500" type="text" field="location" label="" register={register} errors={errors} forceClass="font-bold  text-pink-500" /> */}
            <div className="flex items-center justify-start mt-4">
              <div className="form-control items-start mb-2 mr-4">
                <label className="flex items-start justify-start label cursor-pointer">
                  <input
                    {...register("remote")}
                    type="radio"
                    className="radio radio-warning radio-xs mr-1"
                    value="Onsite"
                  />
                  <span className="label-text font-bold pb-2  text-sm">
                    Onsite
                  </span>
                </label>
              </div>
              <div className="form-control items-start mb-2 mr-4">
                <label className="flex items-start justify-start label cursor-pointer">
                  <input
                    {...register("remote")}
                    type="radio"
                    className="radio radio-warning radio-xs mr-1"
                    value="Remote"
                  />
                  <span className="label-text font-bold pb-2  text-sm">
                    Remote
                  </span>
                </label>
              </div>
              <div className="form-control items-start mb-2">
                <label className="flex items-start justify-start label cursor-pointer">
                  <input
                    {...register("remote")}
                    type="radio"
                    className="radio radio-warning radio-xs mr-1"
                    value="Hybrid"
                  />
                  <span className="label-text font-bold pb-2  text-sm">
                    Hybrid
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="label">
            <span className="label-text  pb-2  text-lg font-bold mb-[-10px]">
              Salary Range
            </span>
          </div>
          <div className="join flex mb-6 gap-2">
            <div className="max-w-[80px]">
              <div>
                <SelectBlock2
                  list={currencies}
                  field="currency"
                  label="Currency"
                  register={register}
                  errors={errors}
                  forceClass="join-item rounded-r-none min-h-[34px]"
                />
              </div>
            </div>
            <div className="flex-grow">
              <InputBlock2
                type="text"
                field="salary_from"
                label="$From"
                register={register}
                errors={errors}
                forceClass="rounded-none min-h-[34px]"
              />
            </div>
            <div className="flex-grow">
              <InputBlock2
                type="text"
                field="salary_to"
                label="$To"
                register={register}
                errors={errors}
                forceClass="rounded-none min-h-[34px]"
              />
            </div>
            <div className="max-w-[100px]">
              <div>
                <SelectBlock2
                  list={["yearly", "monthly", "weekly", "daily", "hourly"]}
                  field="salary_period"
                  label="Frequency"
                  register={register}
                  errors={errors}
                  forceClass="join-item rounded-l-none min-h-[34px]"
                />
              </div>
            </div>
          </div>
          <label className="label-text font-bold pb-2  text-lg">
            How To Apply
          </label>
          <InputBlock2
            type="text"
            field="how_to_apply"
            label=""
            register={register}
            errors={errors}
            forceClass=""
          />
          <div className="grid grid-cols-2 gap-4 mb-4 mt-8">
            <InputBlock2
              type="date"
              field="activation_date"
              label="Post Date"
              register={register}
              errors={errors}
              forceClass="mb-6 font-bold text-xl"
            />
            <InputBlock2
              type="date"
              field="expiration_date"
              label="Expiration Date"
              register={register}
              errors={errors}
              forceClass="mb-6 font-bold"
            />
          </div>
          {/* FIXME: This logic seems wrong */}
          {!userInfo.employer_id && (
            <>
              <div className="flex flex-col gap-2 mb-8">
                <p>
                  <span className="font-bold text-xl">Auto-Sales Info</span>{" "}
                  <br />
                  Email Addresses and information found in this job description:
                </p>
                <div className="flex gap-2 justify-start">
                  <InputBlock2
                    type="text"
                    field="firstName1"
                    placeholder="First Name"
                    label=""
                    register={register}
                    errors={errors}
                    forceClass=""
                    forceClass1="bg-yellow-100 text-gray-800"
                  />
                  <InputBlock2
                    type="text"
                    field="lastName1"
                    placeholder="Last Name"
                    label=""
                    register={register}
                    errors={errors}
                    forceClass=""
                    forceClass1="bg-yellow-100 text-gray-800"
                  />
                  <InputBlock2
                    type="text"
                    field="jobTitle1"
                    placeholder="Job Title"
                    label=""
                    register={register}
                    errors={errors}
                    forceClass=""
                    forceClass1="bg-yellow-100 text-gray-800"
                  />

                  <InputBlock2
                    type="text"
                    field="email1"
                    placeholder="Email"
                    label=""
                    register={register}
                    errors={errors}
                    forceClass=""
                    forceClass1="bg-yellow-100 text-gray-800"
                  />
                  <InputBlock2
                    type="text"
                    field="phone1"
                    placeholder="Phone"
                    label=""
                    register={register}
                    errors={errors}
                    forceClass=""
                    forceClass1="bg-yellow-100 text-gray-800"
                  />
                  <label className="flex items-start justify-start label cursor-pointer">
                    <span className="label-text font-bold pb-2 mr-3 text-sm">HR</span>
                    <input
                      {...register("hrYN1")}
                      type="checkbox"
                      className="mr-1 mt-1"
                    />
                  </label>
                  <label className="flex items-start justify-start label cursor-pointer">
                    <span className="label-text font-bold pb-2 mr-3 text-sm">Department</span>
                    <input
                      {...register("departmentYN1")}
                      type="checkbox"
                      className="mr-1 mt-1"
                    />
                  </label>
                  <label className="flex items-start justify-start label cursor-pointer">
                    <span className="label-text font-bold pb-2 mr-3 text-sm">Recruiter</span>
                    <input
                      {...register("recruiterYN1")}
                      type="checkbox"
                      className="mr-1 mt-1"
                    />
                  </label>
                </div>
                <div className="flex gap-2 justify-start">
                  <InputBlock2
                    type="text"
                    field="firstName2"
                    placeholder="First Name"
                    label=""
                    register={register}
                    errors={errors}
                    forceClass=""
                    forceClass1="bg-yellow-100 text-gray-800"
                  />
                  <InputBlock2
                    type="text"
                    field="lastName2"
                    placeholder="Last Name"
                    label=""
                    register={register}
                    errors={errors}
                    forceClass=""
                    forceClass1="bg-yellow-100 text-gray-800"
                  />
                  <InputBlock2
                    type="text"
                    field="jobTitle2"
                    placeholder="Job Title"
                    label=""
                    register={register}
                    errors={errors}
                    forceClass=""
                    forceClass1="bg-yellow-100 text-gray-800"
                  />
                  <InputBlock2
                    type="text"
                    field="email2"
                    placeholder="Email"
                    label=""
                    register={register}
                    errors={errors}
                    forceClass=""
                    forceClass1="bg-yellow-100 text-gray-800"
                  />
                  <InputBlock2
                    type="text"
                    field="phone2"
                    placeholder="Phone"
                    label=""
                    register={register}
                    errors={errors}
                    forceClass=""
                    forceClass1="bg-yellow-100 text-gray-800"
                  />
                  <label className="flex items-start justify-start label cursor-pointer">
                    <span className="label-text font-bold pb-2 mr-3 text-sm">HR</span>
                    <input
                      {...register("hrYN2")}
                      type="checkbox"
                      className="mr-1 mt-1"
                    />
                  </label>
                  <label className="flex items-start justify-start label cursor-pointer">
                    <span className="label-text font-bold pb-2 mr-3 text-sm">Department</span>
                    <input
                      {...register("departmentYN2")}
                      type="checkbox"
                      className="mr-1 mt-1"
                    />
                  </label>
                  <label className="flex items-start justify-start label cursor-pointer">
                    <span className="label-text font-bold pb-2 mr-3 text-sm">Recruiter</span>
                    <input
                      {...register("recruiterYN2")}
                      type="checkbox"
                      className="mr-1 mt-1"
                    />
                  </label>
                </div>
              </div>
              {/* <div>
                <label className="label-text font-bold text-lg">
                  Regions to post this job:
                </label>
                <div className="flex flex-wrap gap-4">
                  {[
                    "Australia",
                    "Canada",
                    "New Zealand",
                    "United Kingdom",
                    "United States",
                  ].map((region, i) => (
                    <div className="flex gap-1">
                      <div className=" text-left">
                        <input
                          type="checkbox"
                          value={region}
                          defaultChecked={job?.postRegions?.some(
                            (p) => p.postRegion === region
                          )}
                          {...register(`postRegions`)}
                        />
                      </div>
                      <label className={`text-sm text-left text-gray-600`}>
                        {region}
                      </label>
                    </div>
                  ))}
                </div>
              </div> */}
              <label className="label-text font-bold text-lg">
                Regions to post this job:
              </label>
              <div><RegionalPosting postRegions={job?.postRegions} id={job?.id} /></div>
            </>
          )}
          {job && (
            <div className="flex flex-col gap-2 mt-6">
              <div className="flex justify-start gap-2 ">
                <span className="font-bold text-xl">Posted By</span>
                <span>
                  {job.user?.firstName} {job.user?.lastName}, from{" "}
                  {job.user?.orgnization_name}
                </span>
              </div>
              <div className="flex justify-start gap-2 ">
                <span className="font-bold text-xl">Job Loading Duration</span>
                <span className="">{job.jobPostDuration}</span>
              </div>
            </div>
          )}
          {/* WIP */}
          <div className="flex justify-start gap-1">
            <button type="submit" className="btn btn-success" ref={submitRef}>
              {jobStatus}
            </button>
            {content2}
          </div>


          <div className="flex flex-col gap-4 mt-[50px]">
            <div>==== FOR TESTING ONLY ====</div>
            {/* Contacts form */}
            <div className="mt-6 flex flex-col ">
              <label className="text-lg font-bold">Add Contacts to Notify on Job Creation (only for paid jobs)</label>
              {fields.map((field, index) => (
                <div key={field.id} className="flex gap-4 mb-4">
                  <input
                    type="text"
                    placeholder="First Name"
                    {...register(`contacts.${index}.firstName`, { required: true })}
                    className="input-sm border rounded-lg w-full"
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    {...register(`contacts.${index}.lastName`, { required: true })}
                    className="input-sm border rounded-lg w-full"
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    {...register(`contacts.${index}.email`, { required: true })}
                    className="input-sm border rounded-lg w-full"
                  />
                  <label className="flex items-start justify-start label cursor-pointer">
                    <span className="label-text font-bold pb-2 mr-3 text-sm">HR</span>
                    <input
                      {...register(`contacts.${index}.hr`, { required: true })}
                      type="checkbox"
                      className="mr-1 mt-1"
                    />
                  </label>
                  <label className="flex items-start justify-start label cursor-pointer">
                    <span className="label-text font-bold pb-2 mr-3 text-sm">Department</span>
                    <input
                      {...register(`contacts.${index}.department`, { required: true })}
                      type="checkbox"
                      className="mr-1 mt-1"
                      defaultChecked
                    />
                  </label>
                  <label className="flex items-start justify-start label cursor-pointer">
                    <span className="label-text font-bold pb-2 mr-3 text-sm">Recruiter</span>
                    <input
                      {...register(`contacts.${index}.recruiter`, { required: true })}
                      type="checkbox"
                      className="mr-1 mt-1"
                    />
                  </label>
                  <button type="button" className="btn btn-error btn-sm" onClick={() => remove(index)}>
                    Remove
                  </button>
                </div>
              ))}
              <button type="button" className="btn btn-primary mx-auto" onClick={() => append({ name: "", email: "" })}>
                Add Another Contact
              </button>
            </div>

            <div className="flex gap-2">
              <SelectBlock2
                list={regions}
                field="region"
                label="Region"
                register={register}
                errors={errors}
                forceClass="join-item rounded-r-none min-h-[34px]"
              />
              <InputBlock2
                type="text"
                field="country"
                label="Country"
                register={register}
                errors={errors}
                forceClass=""
              />
              <InputBlock2
                type="text"
                field="state"
                label="State"
                register={register}
                errors={errors}
                forceClass=""
              />
              <InputBlock2
                type="text"
                field="city"
                label="City"
                register={register}
                errors={errors}
                forceClass=""
              />
              <InputBlock2
                type="text"
                field="postalCode"
                label="Postal Code"
                register={register}
                errors={errors}
                forceClass=""
              />
            </div>
            <div className="flex flex-col gap-2 mb-8">
              <p>
                {" "}
                <span className="font-bold text-xl">Talent Pool</span> <br />
                Seekers respond to this job
              </p>
              <div className="flex gap-2 justify-start border border-red-700">
                No one responded to this so far
              </div>
            </div>
          </div>
        </div>
      </form>
      {content1}
    </>
  );
  return <div className="overflow-y w-full p-6">
    <p className="text-3xl font-bold text-black shadow-xl px-2 pb-4 mb-6">{isAddMode ? "Post a Job" : "Editing Job"}</p>
    {content}
  </div>;
};
export default JobAddEdit;
