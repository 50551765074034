import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContactCard from "../components/ContactCard";
import { useSelector } from "react-redux";
import {
  useGetEmployerQuery,
  useUpdateEmployerMutation,
} from "../store/apiSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const createSlug = (name) =>
  name
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-\(\)]/g, "")
    .replace(/[\(\)]/g, "");

function AdminHome_Customer(props) {
  const [
    updateEmployer,
    {
      isLoading: isLoadingUpdateEmployer,
      isSuccess: isSuccessUpdateEmployer,
      isError: isErrorUpdateEmployer,
      error: errorUpdateEmployer,
    },
  ] = useUpdateEmployerMutation();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const { data } = useGetEmployerQuery(userInfo.employer_id);

  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [workCulture, setWorkCulture] = useState("");
  const [howToApply, setHowToApply] = useState("");
  const [employeeBenefits, setEmployeeBenefits] = useState("");

  const uniProfileUrl = `https://www.academicjobs.com/employers/${createSlug(
    data?.company_name || ""
  )}/${data?.id}`;

  useEffect(() => {
    if (data?.company_description) {
      setDescription(data.company_description);
    }
    if (data?.shortDescription) {
      setShortDescription(data.shortDescription);
    }
    if (data?.workCulture) {
      setWorkCulture(data.workCulture);
    }
    if (data?.howToApply) {
      setHowToApply(data.howToApply);
    }
    if (data?.employeeBenefits) {
      setEmployeeBenefits(data.employeeBenefits);
    }
  }, [data]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClick = () => {
    updateEmployer({
      id: userInfo.employer_id,
      company_description: description,
      shortDescription: shortDescription,
      workCulture: workCulture,
      howToApply: howToApply,
      employeeBenefits: employeeBenefits,
    });
    setIsEditing(false);
  };

  return (
    <>
      {!data ? (
        <div>Loading...</div>
      ) : (
        <div className="grid grid-cols-1 gap-4 mt-8">
          <div className="col-span-1 md:col-span-4">
            <iframe
              src="https://open.spotify.com/embed/episode/0VE24ItiDutY30Nxd3BL6H"
              width="100%"
              height="152"
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
              style={{
                border: 'none',
                borderRadius: '8px',
                overflow: 'hidden',
                marginBottom: '20px',
              }}
            />
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center gap-4">
                <h2 className="text-gray-blue font-bold text-4xl mb-4">
                  University Profile
                </h2>
                <div className="flex items-center cursor-pointer space-x-4 p-2 rounded-lg shadow-md hover:shadow-lg transition-shadow">
                  <a
                    href={uniProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=""
                  >
                    View Live Profile on
                  </a>
                  <a
                    href={uniProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-2"
                  >
                    <img
                      src="/brands/academic-jobs-logo.svg"
                      alt="academic jobs"
                      width="150"
                      height="150"
                    />
                  </a>
                </div>

              </div>

              <div className="flex items-center">
                <button
                  onClick={isEditing ? handleSaveClick : handleEditClick}
                  className="btn btn-aj ml-4 mt-4"
                >
                  {isEditing ? "Save Changes" : "Edit Profile"}
                </button>
                {isEditing && (
                  <button
                    onClick={handleEditClick}
                    className="btn bg-grey-500 text-grey-100 rounded-full ml-4 mt-4"
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>

            {isEditing ? (
              <div className="space-y-8">
                {/* Short Employer Description */}
                <div className="space-y-2 p-4 border rounded-lg shadow-sm">
                  <label className="text-xl text-amber-500 underline font-bold ">Short Employer Description</label>
                  <p><strong>Suggested Guidelines:</strong> A short summary is vital for the A-Z recruiter listing page and job description details pages. This needs to be impactful within the 255-character limit. It should highlight the key benefits and points from the full job description.</p>
                  <ReactQuill
                    value={shortDescription}
                    onChange={setShortDescription}
                    className="border border-gray-300 rounded-lg"
                    style={{ minHeight: "150px" }}
                  />
                </div>

                {/* Organisation Description */}
                <div className="space-y-2 p-4 border rounded-lg shadow-sm">
                  <label className="text-xl text-amber-500 underline font-bold ">
                    How the organisation shines in Higher Ed (General Description)
                  </label>
                  <p><strong>Suggested Guidelines:</strong> The profile should offer a detailed insight into the organisation, including its research capabilities, successes, founding year, and future plans. Highlighting awards, new research areas, and thought leadership strengthens the employer's appeal. Visual elements are essential.</p>
                  <ReactQuill
                    value={description}
                    onChange={setDescription}
                    className="border border-gray-300 rounded-lg"
                    style={{ minHeight: "150px" }}
                  />
                </div>

                {/* Working Environment */}
                <div className="space-y-2 p-4 border rounded-lg shadow-sm">
                  <label className="text-xl text-amber-500 underline font-bold ">
                    Working Environment & Culture – what it is like for staff on campus
                  </label>
                  <p>
                    <strong>Suggested Guidelines:</strong> The profile should offer information about what it's like to work at the organisation. Candidates are interested in the people they will be working with and the working environment. We also suggest highlighting if your current team is tenured and exceptional, or if the premises and location are appealing.
                  </p>
                  <ReactQuill
                    value={workCulture}
                    onChange={setWorkCulture}
                    className="border border-gray-300 rounded-lg"
                    style={{ minHeight: "150px" }}
                  />
                </div>

                {/* Employee Benefits */}
                <div className="space-y-2 p-4 border rounded-lg shadow-sm">
                  <label className="text-xl text-amber-500 underline font-bold ">Employee Benefits Beyond Salary</label>
                  <p>
                    <strong>Suggested Guidelines:</strong> Candidates are interested in benefits beyond salary, such as training and development opportunities, volunteer days, and continuous career development. These perks should be clearly articulated in the profile.
                  </p>
                  <ReactQuill
                    value={employeeBenefits}
                    onChange={setEmployeeBenefits}
                    className="border border-gray-300 rounded-lg"
                    style={{ minHeight: "150px" }}
                  />
                </div>

                {/* How to Apply */}
                <div className="space-y-2 p-4 border rounded-lg shadow-sm">
                  <label className="text-xl text-amber-500 underline font-bold ">
                    How to apply for job openings, with all appropriate contact details
                  </label>
                  <p>
                    <strong>Suggested Guidelines:</strong> Provide complete contact and address information, including a link to the organisation's website. This allows candidates to research the working environment further and show the employer is being open and transparent.
                  </p>
                  <ReactQuill
                    value={howToApply}
                    onChange={setHowToApply}
                    className="border border-gray-300 rounded-lg"
                    style={{ minHeight: "150px" }}
                  />
                </div>
              </div>


            ) : (
              <div
                className="w-full"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </div>
          <ContactCard personId="jarrod" forceClass="mt-10" />
        </div>
      )}
    </>
  );
}

export default AdminHome_Customer;
