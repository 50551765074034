import React, { useState } from "react";
import DashboardCard from "./profile/DashboardCards";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSendLogoutMutation } from "../views/auth/authApiSlice";
import { logOut } from "../views/auth/authSlice";

const createSlug = (name) =>
  name
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-\(\)]/g, "")
    .replace(/[\(\)]/g, "");

const DashboardSection = ({ employerData }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sendLogout] = useSendLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMenuItemClick = () => {
    // Close the dropdown when a menu item is clicked
    setDropdownOpen(false);
  };

  const uniProfileUrl = `https://www.academicjobs.com/employers/${createSlug(
    employerData.company_name
  )}/${employerData.employer_id}`;

  // Define the menu items as an array of objects
  const menuItems = [
    {
      title: "Dashboard",
      iconPath: "/icons/dashboard.png",
      href: "/dashboard",
    },
    {
      title: "University Profile",
      iconPath: "/icons/uniprofile.png",
      href: "/university-profile",
      // externalUrl: uniProfileUrl,
    },
    {
      title: "Jobs Posted",
      iconPath: "/icons/currentjobs.png",
      href: "/jobs-center1",
    },
    {
      title: "Academic Connect",
      iconPath: "/icons/network-icon.svg",
      href: "/academic-connect",
      subTitle: "Our unique referral program", // Optional
    },
    {
      title: "Post a Job",
      iconPath: "/icons/postajob.png",
      href: "/post-job-employer",
      btnPrimary: true,
    },
  ];

  return (
    <section title="Quick Links (Shortcuts)" className="mt-8">
      {/* Mobile Menu */}
      <div className="md:hidden">
        <button
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="w-full bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded"
        >
          Menu
          <span
            className={`ml-2 transform ${
              dropdownOpen ? "rotate-180" : "rotate-0"
            }`}
          >
            ▼
          </span>
        </button>
        {dropdownOpen && (
          <div className="flex flex-col mt-2">
            {menuItems.map((item, index) => (
              <DashboardCard
                key={index}
                title={item.title}
                iconPath={item.iconPath}
                href={item.href}
                onClick={item.onClick || handleMenuItemClick}
                externalUrl={item.externalUrl || null}
                btnPrimary={item.btnPrimary || false}
                subTitle={item.subTitle}
              />
            ))}
            {/* Logout button visible only on mobile */}
            <DashboardCard
              title="Logout"
              iconPath="/logout-svgrepo-com.svg"
              onClick={() => {
                sendLogout();
                document.cookie =
                  "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                dispatch(logOut());
                navigate("/");
                window.location.reload();
              }}
            />
          </div>
        )}
      </div>
      {/* Desktop Menu */}
      <div className="hidden md:flex gap-4">
        {menuItems.map((item, index) => (
          <DashboardCard
            key={index}
            title={item.title}
            iconPath={item.iconPath}
            href={item.href}
            onClick={item.onClick}
            btnPrimary={item.btnPrimary || false}
            externalUrl={item.externalUrl || null}
            subTitle={item.subTitle}
          />
        ))}
      </div>
    </section>
  );
};

export default DashboardSection;
